import React from 'react'
import ReactWOW from 'react-wow';

//Styles
import '../App.css';
import '../components/Animate/animate.css';
import '../components/effects';

//Multimedia
import ekoVideo from "../multimedia/ekoVideo.mp4";
import videoPoster from "../multimedia/eHala.jpg";
import oSpol from "../multimedia/o_spolocnosti.jpg";
import tech1 from "../multimedia/technologie/tech-1.jpg";
import tech2 from "../multimedia/technologie/tech-2.jpg";
import tech3 from "../multimedia/technologie/tech-3.jpg";
import tech4 from "../multimedia/technologie/tech-4.jpeg";
import tech5 from "../multimedia/technologie/tech-5.jpg";
import tech6 from "../multimedia/technologie/tech-6.png";


export const homePage = () => (
    <div>
        <section className="container-fluid">
            <div className="row">
                <ReactWOW animation='fadeIn'>
                    <div className="col-lg-12">
                        <video className="videoBox ekometalVideo" poster={videoPoster} autoPlay controls muted loop>
                            <source src={ekoVideo} type="video/mp4">
                            </source>
                        </video>   
                    </div>
                </ReactWOW>
            </div>
        </section>

        <section className="container">
            <div className="row">
                <ReactWOW animation='fadeIn' delay="100ms">
                    <div className="col-sm-12">
                        <h1 className="techTitle">Naše Technológie</h1>
                        <hr className="hrCustomHP"></hr>
                    </div>
                </ReactWOW>
            </div>
            <div className="row">
                <ReactWOW animation='fadeInLeft'>
                    <div className="col-sm-4 homeFilter">
                        <div className="mainOV"></div>
                        <img src={tech1} id="techStroje" alt="sustruh"/>
                        <div className="centered">
                            <h2>Sústružnícke centrá</h2>
                            <a href="/technologia"><p className="odkazTechnologie">Viac <i className="fa fa-arrow-right"></i></p></a>
                        </div>
                    </div>
                </ReactWOW>
                <ReactWOW animation='fadeInLeft' delay="200ms">
                    <div className="col-sm-4 homeFilter">
                    <div className="mainOV"></div>
                        <img src={tech2} id="techStroje" alt="freza"/>
                        <div className="centered">
                            <h2>Frézovacie centrá</h2>
                            <a href="/technologia"><p className="odkazTechnologie">Viac <i className="fa fa-arrow-right"></i></p></a>
                        </div>
                    </div>
                </ReactWOW>
                <ReactWOW animation='fadeInLeft' delay="400ms">
                    <div className="col-sm-4 homeFilter">
                    <div className="mainOV"></div>
                        <img src={tech3} id="techStroje" alt="brusenie"/>
                        <div className="centered">
                            <h2>Brúsenie</h2>
                            <a href="/technologia"><p className="odkazTechnologie">Viac <i className="fa fa-arrow-right"></i></p></a>
                        </div>
                    </div>
                </ReactWOW>
            </div>
            <div className="row">
                <ReactWOW animation='fadeInLeft'>
                    <div className="col-sm-4 homeFilter">
                    <div className="mainOV"></div>
                        <img src={tech4} id="techStroje" alt="trasak"/>
                        <div className="centered">
                            <h2>Omieľacie zariadenia</h2>
                            <a href="/technologia"><p className="odkazTechnologie">Viac <i className="fa fa-arrow-right"></i></p></a>
                        </div>
                    </div>
                </ReactWOW>
                <ReactWOW animation='fadeInLeft' delay="200ms">
                    <div className="col-sm-4 homeFilter">
                    <div className="mainOV"></div>
                        <img src={tech5} id="techStroje" alt="meradlo"/>
                        <div className="centered">
                            <h2>Výstupná kontrola</h2>
                            <a href="/technologia"><p className="odkazTechnologie">Viac <i className="fa fa-arrow-right"></i></p></a>
                        </div>
                    </div>
                </ReactWOW>
                <ReactWOW animation='fadeInLeft' delay="400ms">
                    <div className="col-sm-4 homeFilter">
                    <div className="mainOV"></div>
                        <img src={tech6} id="techStroje" alt="pila"/>
                        <div className="centered">
                            <h2>Delenie materiálu</h2>
                            <a href="/technologia"><p className="odkazTechnologie">Viac <i className="fa fa-arrow-right"></i></p></a>
                        </div>
                    </div>
                </ReactWOW>
            </div>
        </section>
        <section className="container" id="oSpolBox">
            <div className="row">
                <ReactWOW animation='fadeIn'>
                    <div className="col-lg-8">
                        <div>
                            <h2 className="oSpolTitle">O spoločnosti</h2>
                            <p className="oSpolText text-justify">Spoločnosť Ekometal, spol. s r.o. bola založená v roku 2003. 
                            Od svojho vzniku prešla postupným vývojom a vysokou kvalitou svojich služieb uspela ako na slovenskom, tak aj na európskom trhu. 
                            Spoločnosť je primárne zameraná na trieskové obrábanie kovových súčiastok CNC technológiou.
                            Vyrábané dielce sa v prevažnej miere používajú v robotike a automatizácii.</p>
                        </div>
                    </div>
                <div className="col-lg-4">
                    <img src={oSpol} alt="oSpol" className="oSpolimg"/>
                </div>
                </ReactWOW>
            </div>
        </section>
        <section id="mapFade" className="container-fluid">
            <div className="row">
                <ReactWOW animation='fadeIn'>
                    <div className="col-lg-12">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13370.022249276708!2d19.928209334547454!3d48.68899283057682!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473fdb9cc7d38f79%3A0x303e62fdf39ac4eb!2sEkometal%20s.r.o.!5e0!3m2!1ssk!2scz!4v1580808259561!5m2!1ssk!2scz" 
                        id="G_map" title="EkometalVideo"></iframe>
                    </div>
                </ReactWOW>
            </div>
        </section>
    </div>
)
